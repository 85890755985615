<script>
import appConfig from "@/app.config";
import {email, required, sameAs,minLength} from "vuelidate/lib/validators";
import Vue from 'vue'

/**
 * Register sample page
 */
export default {
  page: {
    title: "Register",
    meta: [{ name: "description", content: appConfig.description }]
  },
  name: 'Register',
  data() {
    return {
      typesubmit: false,
      username_nv:false,
      email_nv:false,
      user: {
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        accept_cond:false
      }

    }
  },
  validations: {
    user:{
      email: {required,email},
      username: {required,minLength: minLength(4)},
      password: {required},
      confirmPassword: {required, sameAsPassword: sameAs("password")},
      accept_cond:{sameAs: sameAs( () => true )}
    },
  },

  methods: {
    handleSubmit() {
      this.typesubmit = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.register();

    },
    // search(type){
    //   this.email_nv = false
    //   let a = {}
    //   if(type == 'username'){
    //     this.username_nv = false
    //      a.input = this.user.username
    //   }
    //   else if(type == 'email'){
    //     this.username_nv = false
    //     a.input = this.user.email
    //   }
    //   a.type = type
    //   this.$http
    //           .post('register/check', a)
    //           .then(response => {
    //             if(response.data.type == 'username') this.username_nv = response.data.found
    //             if(response.data.type == 'email') this.email_nv = response.data.found
    //           })
    //           .catch(error => {
    //             console.log(error)
    //           })
    // },
    register() {
      this.$http
              .post('users', this.user)
              .then(response => {
                console.log(response.data.access_token)
                const token = response.data.access_token;
                localStorage.setItem('token', token)
                Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
                this.$store.commit('updatePusher',token)
                this.$router.push({name:'confirmationEmail'})
                console.log(response)
              })
              .catch(error => {
                console.log(error)
                this.showError(Object.values(error.response.data.errors))
                //Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.message})
              })
    },
    showError(e) {
      for (var i = 0; i < e.length; i++) {
        this.$alertify.error(e[i][0]);
      }

    },
  }

};
</script>
<template>

    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">

              <div class="card-body pt-0">
                <div class="text-center mt-4 pt-4 mb-4">
                  <img src="@/assets/images/logo-light-icon.png" alt   role="button"  @click="$router.push({'name':'Crisi5'})"  width="100" />
                </div>

                <div class="p-2">


                  <form class="form-horizontal pt-2"  id="form"  method="POST" @submit.prevent="handleSubmit">
                    <div class="form-group">
                      <label for="username">{{$t('logins.username')}}</label>
                      <!--                              @input ="search('username')"-->
                      <input
                              type="text"
                              class="form-control"
                              id="username" v-model="user.username"
                              :placeholder="$t('logins.username_text')"
                              :class="{ 'is-invalid': typesubmit && $v.user.username.$error }"
                      />
                      <div v-if="typesubmit && $v.user.username.$error" class="invalid-feedback">
                        <span v-if="!$v.user.username.required"> {{$t('validation.required')}}</span>
                        <span v-else-if="!$v.user.username.minLength">username >= 4 caracters</span>
                      </div>
                      <div v-if="username_nv" class="text-danger">{{$t('validation.user_exist')}}</div>
                    </div>
                    <div class="form-group">
                      <label for="useremail">{{$t('logins.email')}}</label>
<!--                       @input ="search('email')"-->

                      <input
                        type="email" class="form-control"
                        id="useremail" v-model="user.email"
                        :placeholder="$t('logins.email_text')"
                        :class="{ 'is-invalid': typesubmit && $v.user.email.$error }"/>
                      <div v-if="typesubmit && $v.user.email.$error" class="invalid-feedback">
                        <span v-if="!$v.user.email.required"> {{$t('validation.required')}}</span>
                        <span
                                v-else-if="!$v.user.email.email"
                        >{{$t('validation.email')}}</span>
                      </div>
                      <div v-if="email_nv" class="text-danger">{{$t('validation.email_exist')}}</div>
                    </div>

                    <div class="form-group">
                      <label for="userpassword">{{$t('logins.password')}}</label>
                      <input
                        type="password"
                        class="form-control"
                        id="userpassword" v-model="user.password"
                        :placeholder="$t('logins.password_text')"
                        :class="{ 'is-invalid': typesubmit && $v.user.password.$error }"
                      />
                      <div v-if="typesubmit && $v.user.username.$error" class="invalid-feedback">
                        <span v-if="!$v.user.username.required"> {{$t('validation.required')}}</span>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>{{$t('logins.confirmPassword')}}</label>
                      <input type="password" class="form-control"
                              :placeholder="$t('pwd.place_holder3')"
                              v-model="user.confirmPassword"
                              :class="{ 'is-invalid': typesubmit && $v.user.confirmPassword.$error }"/>
                      <div v-if="typesubmit && $v.user.confirmPassword.$error" class="invalid-feedback">
                        <span v-if="!$v.user.confirmPassword.required"> {{$t('validation.required')}}</span>
                        <span
                                v-else-if="!$v.user.confirmPassword.sameAsPassword"
                        >{{$t('pwd.same')}}</span>
                      </div>
                    </div>
                    <div class="custom-control custom-checkbox mt-3">
                      <input type="checkbox" class="custom-control-input" id="customControlInline" v-model="user.accept_cond" :class="{ 'is-invalid': typesubmit && $v.user.accept_cond.$error }"/>
                      <label class="custom-control-label" for="customControlInline">{{$t('logins.accept_1')}}
                        <router-link :to="{name:'TOS'}" class="font-weight-medium text-primary">{{$t('tos.title')}}</router-link>{{$t('logins.accept_2')}}
                        <router-link :to="{name:'PrivacyPolicy'}" class="font-weight-medium text-primary"> {{$t('privacy.title')}}</router-link>
                      </label>
                    </div>
                    <div class="mt-4">
                      <button class="btn btn-primary btn-block" type="submit">{{$t('logins.register')}}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="mt-5 text-center">
              <p>
                {{$t('logins.sign_in_text')}}
                <router-link
                  tag="a"
                  to="/login"
                  class="font-weight-medium text-primary"
                >{{$t('logins.login')}}</router-link>
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>

</template>